import React, { Component } from "react";
import Layout from "../components/layout";
import Container from "../components/container";
import * as styles from "./kontakt.module.css";
import { FaInfo, FaPhone } from "react-icons/fa";
import SEO from "../components/seo";
import Hero from "../components/HeroBanner";
import heroImage2 from "../images/empireo.jpg";
import ContactForm from "../components/ContactForm";
import { graphql } from "gatsby";
export const query = graphql`
  {
    heroBg: file(relativePath: { eq: "empireo.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
const Kontakt = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Kontakt - poznaj profesjonalne rozwiązania dla biznesu"
        description="Kontakt ✅ Rozwiązania dedykowane • Systemy do zarządzania firmą • Systemy CRM • Platformy B2B • Systemy do serwisu • Questy - Twój biznes wygrywa!"
      />
      <Hero heroImage={data.heroBg} justifyContent="center">
        <h1>Kontakt</h1>
        <center>
          <p>Odwiedź nas, napisz lub zadzwoń</p>
        </center>
      </Hero>
        <div
        style={{
          paddingBottom: 30,
          paddingTop: 30,
        }}>
      <Container className={styles.wrapper}>
        <div className={styles.formWrapper}>
          <div className={styles.contactHeader}>
            <FaInfo size="50px" />
            <p>
              <strong>
                Masz pytania? Wypełnij formularz, a my skontaktujemy się z Tobą
              </strong>
            </p>
          </div>
          <ContactForm systemType="questy" />
        </div>
        <div className={styles.mapContainer}>
          <div className={styles.contactHeader}>
            <FaPhone size="50px" />
            <p>
              <strong>…albo po prostu do nas zadzwoń</strong>
            </p>
            <p>
              <a
                className={styles.phoneNum}
                href="tel:+48506504540"
                title="+48 506 504 540"
              >
                +48 506 504 540
              </a>
            </p>
            <p>
              Pracujemy od poniedziałku do piątku <br />w godzinach 8 — 16
            </p>
            <br />
          </div>
          <center>
            <iframe
              title="Adres Questy"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2433.608052588435!2d16.913769930151982!3d52.413779407663064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47045b4b2800c3e7%3A0x5336529b5602e3de!2sQuesty!5e0!3m2!1spl!2spl!4v1488363170109"
              // style="border: 0px none; pointer-events: none;"
              allowfullscreen=""
              width="400"
              height="300"
              frameborder="0"
            />
          </center>
        </div>
      </Container>
      </div>
    </Layout>
  );
};

export default Kontakt;
