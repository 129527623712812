// extracted by mini-css-extract-plugin
export var wrapper = "kontakt-module--wrapper--kC2_w";
export var contactForm = "kontakt-module--contactForm--3o0x2";
export var required = "kontakt-module--required--2kQeV";
export var allowProcessData = "kontakt-module--allowProcessData--2ww97";
export var contactHeader = "kontakt-module--contactHeader--29eq_";
export var mapContainer = "kontakt-module--mapContainer--2cEGh";
export var phoneNum = "kontakt-module--phoneNum--1jSjv";
export var formWrapper = "kontakt-module--formWrapper--2akA4";
export var processDataLabel = "kontakt-module--processDataLabel--2EHKe";
export var tooltip = "kontakt-module--tooltip--3XFx1";
export var mobileDataProcess = "kontakt-module--mobileDataProcess--3Ci9q";